@import "assets/scss/fonts";

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Bango Pro', "Helvetica Neue", sans-serif;
  font-size: 24px;
}

.title {
  font-size: 63px;
  line-height: 56px;
}

.cols {
  display: flex;
  flex-wrap: wrap;
  .col {
    width: 50%;
  }
}

.inner {
  width: 1568px;
  margin: 0 auto;
  padding: 0 50px;
}
