@import "./src/assets/scss/mixins";
$delta: 1.4;

@media only screen and (max-width: 1680px)  {
  $delta: 1.8;
  body, html {
    font-size: pxtovw2(24);
    line-height: pxtovw2(24);
  }

  .inner {
    width: 100%;
    margin: 0 auto;
    padding: 0 pxtovw2(54);
    box-sizing: border-box;
    //text-align: center;
  }

  .cols {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
  }
}

@media only screen and (max-width: 767px)  {
  body, html {
    font-size: pxtovw2(40);
    line-height: pxtovw2(42);
  }

  .inner {
    width: 100%;
    margin: 0 auto;
    padding: 0 pxtovw2(54 * $delta);
    box-sizing: border-box;
    text-align: center;
  }

  .cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .col {
      width: 100%;
    }
  }
}


