@font-face {
  font-family: 'Lalezar';
  src: url('../fonts/Lalezar-Regular.eot');
  src: url('../fonts/Lalezar-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lalezar-Regular.woff2') format('woff2'),
  url('../fonts/Lalezar-Regular.woff') format('woff'),
  url('../fonts/Lalezar-Regular.ttf') format('truetype'),
  url('../fonts/Lalezar-Regular.svg#Lalezar-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bango Pro';
  src: url('../fonts/BangoPro.eot');
  src: url('../fonts/BangoPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BangoPro.woff2') format('woff2'),
  url('../fonts/BangoPro.woff') format('woff'),
  url('../fonts/BangoPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
